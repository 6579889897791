import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  downloadCSV,
  FunctionField,
} from "react-admin"
import jsonExport from "jsonexport/dist"
import Chip from "@material-ui/core/Chip"

import DefaultActions from "./DefaultActions"
import DefaultPagination from "./DefaultPagination"
import React from "react"
import TimestampField from "./TimestampField"
import { DateRangeFilterWithField } from "./DateRangeFilter"
import { Box } from "@material-ui/core"

function ClaimRejectFilters (
  props: {
    setFilters?: (filterMap: Record<string, any>) => void,
    filterValues?: Record<string, any>,
  },
) {

  return (
    <Filter {...props}>
      <TextInput label='Filter by claim id' source='id' alwaysOn />
      <TextInput label='Filter by email' source='userEmail' alwaysOn />
      {/* @ts-expect-error: Property 'alwaysOn' does not exist */}
      <Box mb={2} alwaysOn>
        <DateRangeFilterWithField source='createdTime' label='Created Time' />
      </Box>
    </Filter>
  )

}

function getUtmSource (utmParams: { source: string; value: any; }[]) {
  const utmSource = utmParams.find(
    (utm: { source: string; value: any; }) => 
      utm.source === "utm_source",
  )

  return utmSource?.value
}

function exporter (claims: any) {
  const exportClaims: {[key: string]: any}[] = []
  for (const claim of claims) {
    const providers = claim?.customFieldValues?.providers?.value
    const utmSource = claim.utmParams ? getUtmSource(claim.utmParams) : ""

    for (const provider of providers) {
      exportClaims.push({
        "Created Time": claim.createdTime,
        "Claim ID": `${claim.firstName} ${claim.lastName} (${claim.id})`,
        "First Name": claim.firstName,
        "Last Name": claim.lastName,
        "E-mail": claim.userEmail,
        "Phone Number": claim.phoneNumber,
        "Amount of Casinos": providers.length,
        Operator: provider.company,
        Casino: provider.name,
        "Est. Losses": provider.estimatedLosses,
        "Claim Action Status": claim.claimActionStatus,
        "Lead Source": claim.leadSource,
        "UTM Source": utmSource,
      })
    }
  }

  jsonExport(exportClaims, {
    fillGaps: true,
  }, (err, csv) => {
    downloadCSV(csv, "claims-reject")
  })
}

export function ClaimRejectList ({ ...props }) {
  return (
    <List
      {...props}
      filter={{
        type: "rejects",
      }}
      filters={<ClaimRejectFilters />}
      actions={<DefaultActions />}
      sort={{ field: "createdTime", order: "DESC" }}
      perPage={25}
      pagination={<DefaultPagination />}
      exporter={exporter}
    >
      <Datagrid rowClick='show'>
        <TimestampField
          source='createdTime'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <TextField label='Claim ID' source='id' sortable={false} />
        <TextField label='First Name' source='firstName' sortable={false} />
        <TextField label='Last Name' source='lastName' sortable={false} />
        <TextField label='E-mail' source='userEmail' sortable={false} />
        <TextField
          label='Phone Number'
          source='phoneNumber'
          sortable={false}
          style={{ whiteSpace: "nowrap" }}
        />
        <FunctionField
          source="providers"
          label='Claims'
          render={record => {
            const providers: {[key: string]: any}[] =
              record?.customFieldValues?.providers?.value
            const providerRender = providers.map(provider => {
              return <Chip
                label={
                  `${provider.name} (${provider.company}) ` +
                    provider.estimatedLosses
                }
              />
            })
            return <>{providerRender}</>
          }}
        />
        <TextField
          label='Claim Action Status'
          source='claimActionStatus'
          sortable={false}
        />
        <TextField label='Lead Source' source='leadSource' sortable={false} />
        <FunctionField
          source="utmParams"
          label='Utm Source'
          render={record => {
            if (!record?.utmParams) {
              return ""
            }

            const utmSource = record?.utmParams.find(
              (utm: { source: string; value: any; }) => 
                utm.source === "utm_source",
            )

            return utmSource?.value
          }}
        />
      </Datagrid>
    </List>
  )
}
